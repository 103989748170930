@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 10;
  }
}

.container {
  width: 100%;
  margin-top: 138px;
  @media(max-width: 768px) {
    margin-top: 34px;
  }
  @media(max-width: 1024px) {
    padding: 0 24px !important;
  }

  @media(max-width: 1440px) {
    padding: 0 40px;
  }

  @media(max-width: 1700px) {
    padding: 0 80px;
  }

  @media (min-width: 1700px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1581px;
    padding: 0;
  }


}

.searchRow {
  display: grid;
  grid-template-columns: 48% 52%;
  gap: 75px;
}


.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  /* display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  } */
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  /* padding: 0 24px 24px 24px; */

  @media (--viewportLarge) {
    /* padding: 0 36px 36px 36px; */
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  margin-top: 100px;
}

.mainWrapper {
  background-color: #fff;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  /* width: 100vw; */
  height: 100vh;

  & .canvas {
    width: 100%;
  }
}

.mapBox {
  position: relative;

  & .searchInput {
    position: absolute;
    z-index: 9;
    width: 628px;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    background: #FFFFFF;
    border: 0.5px solid #939393;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 20%);
    border-radius: 4px;
  }
}